import React from "react";
import { privacyData } from "../../../constants/privacyData";
import PrivacyCard from "../../molecules/PrivacyCard/PrivacyCard";
import * as styles from "./PrivacyGrid.module.scss";

function PrivacyGrid(){
    return(
        <div className={styles.container}>
            <h1>Complete Website Security</h1>
            <p>We take privacy, security and international protocols seriously. Here you find our policy documents. <br/> Additional information available upon request.</p>
            <div className={styles.data}>
                {privacyData?.map((item,i)=>{
                    return(
                        <PrivacyCard key={i} {...item}/>
                    )
                })}
            </div>
        </div>
    );
}
export default PrivacyGrid;
