import React from "react";
import Header from "../components/atoms/header";
import PrivacyBanner from "../components/molecules/PrivacyBanner/PrivacyBanner";
import PrivacyGrid from "../components/ogranisms/PrivacyGrid/PrivacyGrid";

function PrivacyPolicy(){
    return(
        <main>
            <Header/>
            <PrivacyBanner />
            <PrivacyGrid />
        </main>
    );
}
export default PrivacyPolicy;