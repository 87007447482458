import * as styles from "./PrivacyBanner.module.scss";
import React from "react";
function PrivacyBanner(){
    return(
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>Privacy Policy</h1>
                <p>Explore our documents related to rights, policy and other practical uses of our software <br /> matching international standards</p>
            </div>
        </div>
    );
}
export default PrivacyBanner;