import React from "react";
import * as styles from "./PrivacyCard.module.scss";
import {TfiArrowCircleRight} from "react-icons/tfi";
function PrivacyCard({icon,title,description,to}){
    return(
        <div className={styles.card}>
          {icon}
          <h3>{title}</h3>
          <p>{description}</p>
          <a aria-label="redirect" target={"_blank"} href={to} className={styles.icon}><TfiArrowCircleRight/> Learn More</a>
        </div>
    );
}
export default PrivacyCard;