import React from "react";
import Cookie from "../svg/cookie.svg";
import Shieldsecurity from "../svg/shieldsecurity.svg"
import Shakehand from "../svg/shakehand.svg"
import File from "../svg/file.svg"
import Clock from "../svg/clockk.svg"
import Lock from "../svg/lock1.svg"
import Notificationbing from "../svg/notificationbing.svg"
import UserSearch from "../svg/usersearch.svg"
import Balance from "../svg/balance-icon.svg"
import ListOfUsers from "../svg/list-of-users.svg"
import GlobalRefresh from "../svg/globalrefresh.svg"

export const privacyData= [
    {
        icon:<Shieldsecurity/>,
        title:"Privacy Policy",
        description:"Outlines how your personal data is collected, used, and protected by MASTER WiZR.",
        to:"/mw-privacy-policy.pdf"
    },
    {
        icon:<Shakehand/>,
        title:"Data Processing Agreement (DPA)",
        description:"Describes data handling and security measures between a data controller and processor.",
        to:"/mw-data-processing-agreement.pdf"
    },
    {
        icon:<File/>,
        title:"Terms of Service (ToS)",
        description:"Rules a user must agree to for using a product or service.",
        to:"/mw-terms-of-service.pdf"
    },
    {
        icon:<Cookie/>,
        title:"Cookie Policy",
        description:"Explains the use of cookies for user data collection and web tracking.",
        to:"/mw-cookie-policy.pdf"
    },
    {
        icon:<Clock/>,
        title:"Data Retention Policy",
        description:"Specifies how long data is stored and conditions for its deletion or disposal.",
        to:"/mw-data-retention-policy.pdf"
    },
    {
        icon:<Lock/>,
        title:"Security Policy",
        description:"States how a company secures its networks, systems, and data from threats.",
        to:"/mw-security-policy.pdf"
    },
    {
        icon:<Notificationbing/>,
        title:"Data Breach Notification Policy",
        description:"Protocol for informing affected parties in case of a data security breach.",
        to:"/mw-data-breach-notification-policy.pdf"
    },
    {
        icon:<UserSearch/>,
        title:"Data Subject Access Request (DSAR) Policy",
        description:"Process for handling requests from data subjects to access their personal data.",
        to:"/mw-data-subject-access-request-policy.pdf"
    },
    {
        icon:<Balance/>,
        title:"Data Protection Impact Assessment (DPIA)",
        description:"A process to identify, assess and mitigate data protection risks in projects.",
        to:"/mw-data-protection-policy.pdf"
    },
    {
        icon:<ListOfUsers/>,
        title:"Sub-processor List",
        description:"Listing of third-party data processors used by a company in its operations.",
        to:"/mw-subprocessor-management-policy.pdf"
    },
    {
        icon:<GlobalRefresh/>,
        title:"Data Transfer Agreement",
        description:"Regulates how data is transferred between two entities across different jurisdictions.",
        to:"/mw-data-transfer-agreement.pdf"
    },
]
